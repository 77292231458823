<template>
  <div>
  <div class="shop-button" v-if="jywShop && $route.meta?.module !== 'lmShop'"  @click="goToShop">
    <slot>
      <!-- <i class="iconfont icon-wodegongyingshang font14"/> -->
      <span class="">进入店铺</span>
    </slot>
  </div>
  <div class="shop-button" v-if="jywShop && $route.meta?.module !== 'lmShop' && jywShop.openKfFlag" @click="goToKf">
    <slot>
      <!-- <i class="iconfont icon-wodegongyingshang font14"/> -->
      <span class="m-l-4">在线客服</span>
    </slot>
  </div>
    <div class="shop-button" v-if="$route.meta?.module !== 'lmShop' && showContact && erpTenantId">
      <slot>
        <!-- <i class="iconfont icon-wodegongyingshang font14"/> -->
        <infoCard :erpTenantId="erpTenantId"/>
      </slot>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import { getContactWay } from '@/api_lm/lmPortals/mySupplier'
import InfoCard from "@/views/lmPortals/components/info-card";
let that=null
export default {
  name: 'shopButton',
  components: {InfoCard},
  props: {
    jywShop: {
      type: Object,
      default:()=>{
        return null
      }
    },
    showContact:{
      type: Boolean,
      default() {
        return false
      }
    },
    erpTenantId:{
      type: [Number, String],
      default: 0
    }
  },
  computed:{
    ...mapState('lmPortals', ['userInfo']),
  },
  methods: {
    goToShop() {
      if (!this.jywShop) this.$message.error('店铺信息缺失')
      if (process.env.NODE_ENV === 'production') {
        window.open(`https://${this.jywShop.domainNamePath}.91jyw.com`, '_blank');
      }else{
        // 测试环境跳转自己的的店铺域名
        let routeUrl = this.$router.resolve({
          path: `/lmShop/home?tid=${this.jywShop.gsbTenantId}`
        });
        window.open(routeUrl.href, '_blank');
      }
    },
    goToKf(){
      if (!this.userInfo) return this.$login({confirm:()=>window.location.reload()})
      let url = `https://${window.location.host}/im/#/pages/index/index?tid=`+this.jywShop.gsbTenantId+"&timestamp="+new Date().getTime();;
      // url = url + "&tk=eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ3d3cuNTFzZXJwLmNvbSIsImF1ZCI6IndsbS5pbnRlcm5hbC10ZXN0LjUxaGdwLmNvbSIsInN1YiI6IjZMODJGd3hnSkRtUDdOTjFUQ2cxMUxmcnB2bTJXMGNBUnE1d3Q0RWFxc21xQmgxTndnVVR5a25UbVV5V0kwR1puMXU3ek9pR0hWaWRaU3l4eno4U09nPT0iLCJpYXQiOjE3MzQ5MzY0NDEsImV4cCI6MTczNDk3OTY0MX0.Rm_sm4Q0SOkjSSzI-15qdiaJx-njpoXFjzcBodD49K0"
      window.openKefuWindow(url);
    },
  }
}
</script>

<style lang="scss" scoped>
.shop-button{
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: #0052D9
}
/* 可以根据需要添加样式 */
</style>
