<template>
  <el-dialog class="wlm-login" :visible.sync="dialogVisible" width="440px" :append-to-body="true" :before-close="close">
    <Login v-if="!isRegister" @changeType="isRegister = !isRegister" @onSuccess="onSuccess"/>
    <Register v-else @changeType="isRegister = !isRegister" @onSuccess="onSuccess"/>
  </el-dialog>
</template>
<script>
import Register from "@/components/wlm-login/register";
import Login from "@/components/wlm-login/login";

export default {
  name: "wlm-login",
  components: {
    Register,
    Login,
  },
  props: {
    // 是否注册
    isRegister: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isResetPwd: false, // 是否重置密码
      dialogVisible: false
    };
  },
  methods: {
    // 登录/注册成功后关闭弹窗
    onSuccess() {
      this.$emit('confirm');
      this.dialogVisible = false;
    },

    open() {
      this.dialogVisible = true;
    },

    close() {
      this.$emit('cancel'); // 触发关闭事件
      this.dialogVisible = false;
    }
  }
}
</script>
<style lang="scss" scoped>
.wlm-login {
  :deep(.el-dialog) {
    border-radius: 8px;

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      color: rgba(0, 0, 0, 0.9);
      padding: 40px;
    }

    .el-dialog__footer {
      padding: 0;
    }

    .el-icon-close:before {
      font-size: 24px;
      color: #666666;
    }
  }

}
</style>
