// 集成封装的选择组件
import hgpNoResult from './hgp-no-result'
import hgpDialog from './hgp-dialog'
import hgpSelect from './hgp-select'
import hgpScanPicture from './hgp-scan-picture'
import hgpMachine from './hgp-machine'
import hgpImage from './hgp-image'
import hgpCartPackage from './hgp-cart-package';
import hgpInputNumber from './hgp-input-number'
import hgpEditAddress from './hgp-edit-address'
import wlmMachine from './wlm-machine'
import wlmScanPicture from './wlm-scan-picture'
import wlmCartPackage from './wlm-cart-package';
import wlmLogin from './wlm-login';
import DialogApplyVip from "./dialog-apply-vip";


const components = [
  hgpNoResult,
  hgpDialog,
  hgpSelect,
  hgpScanPicture,
  hgpMachine,
  hgpImage,
  hgpCartPackage,
  hgpInputNumber,
  hgpEditAddress,
  wlmScanPicture,
  wlmMachine,
  wlmCartPackage,
  wlmLogin,
  DialogApplyVip,
];
const install = (Vue) => {
  if (install.installed) return;

  components.forEach((component) => {
    Vue.use(component);
  });
};

export default install;

// const requireComponent = require.context('./', true, /\.vue$/)

// const install = (Vue) => {
// 	if(install.installed) return
// 	install.installed
// 	console.log(requireComponent.keys())
// 	requireComponent.keys().forEach((fileName) => {
// 		const config = requireComponent(fileName)
// 		console.log(config)
// 		const componentName = config.default.name

// 		Vue.component(componentName, config.default || config)
// 	})

// }

// if(typeof window !== 'undefined' && window.Vue) install(window.Vue)
// export default install;
