import wlmLogin from "@/components/wlm-login/main";
import {toLmErp,routeERP, replaceUrl} from "@/utils/common";
import Vue from "vue";
import gather from "@/utils/utils"; // 示例方法

// 创建登录弹窗的方法
let dialogInstance = null;

/**
 * @param config 配置信息
 * @param confirm 确定
 * @param cancel 取消
 */
const login = function ({config = {}, confirm = () => {}, cancel = () => {}} = {}) {
  // 如果已存在对话框实例，销毁它
  if (dialogInstance) {
    dialogInstance.$destroy();
    dialogInstance.$el.remove();
  }

  // 动态创建对话框组件
  const DialogConstructor = Vue.extend(wlmLogin);
  dialogInstance = new DialogConstructor({
    store: this.$store,  // 手动注入 Vuex store
    router: this.$router, // 手动注入 Vue Router
    route: this.$route,   // 手动注入当前路由对象
  });

  // 挂载实例并追加到 DOM
  dialogInstance.$mount();
  document.body.appendChild(dialogInstance.$el);

  // 合并传入配置到实例
  Object.assign(dialogInstance, config);

  // 打开对话框
  dialogInstance.open();

  // 回调 使用方式
  dialogInstance.$on('confirm', () => confirm());
  dialogInstance.$on('cancel', () => cancel());

  // Promise 使用方式
  return new Promise((resolve, reject) => {
    dialogInstance.$on("confirm", () => resolve({type:'confirm'}));
    // dialogInstance.$on("cancel", () => reject('cancel'));
    dialogInstance.$on("cancel", () => resolve({type:'cancel'}));
  });
};

// 统一注册所有全局方法和属性
export const vuePrototype = (Vue) => {
  Vue.prototype.routeERP = routeERP;  // ERP页签跳转
  Vue.prototype.$toLmErp = toLmErp;   // ERP内联盟页签跳转
  Vue.prototype.replaceUrl = replaceUrl; // 示例公共方法
  Vue.prototype.$eventHub = Vue.prototype.$eventHub || new Vue(); // 全局组件通讯库
  Vue.prototype.$isBaseUnitDecimalFraction = gather.isBaseUnitDecimalFraction;
  Vue.prototype.$login = login; // 注册 $login 方法

};
